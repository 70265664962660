<template>
  <div :class="show ? 'h5main main' : 'main'">
    <mtop />
    <div class="banner">
        <img
            src="../assets/ximg/banner-wky@2x.png"
            alt="wky"
            class="wky"
          />
    </div>
    <div class="content">
      <div class="contbox">
        <div class="txtbox">
          <div class="title">微客赚平台简介</div>
          <div class="next">
            <div class="t1">
                   微客赚是星润科技公司基于边缘计算技术研发的云服务产品，致力于帮助用户共享闲置的带宽资源进行流量变现,允许任何人将闲置的宽带、存储、计算等资源接入微客赚平台，构建成一个低成本、低延时、高质量的云服务给头部的视频厂商，从而获得一定的奖励。
            </div>
          </div>
        </div>
        <div class="imgbox">
          <img
            src="../assets/ximg/wky.png"
            alt="NFT"
            title="NFT"
            class="img"
          />
        </div>
      </div>
    </div>
    <div class="imgNum">
        <img
            src="../assets/ximg/numberapp@2x.png"
            alt="wky"
            class="img"
            v-if="show"
          />
          <img
          v-else
            src="../assets/ximg/number@2x.png"
            alt="wky"
            class="img"
          />
    </div>
    <div class="content">
      <div class="contbox">
     
        <div class="txtbox">
          <div class="title">最多三步 实现稳定收益</div>
          <div class="next">
            <div class="t1">参与微客赚共享带宽奖励计划所需条件 ：网络宽带 、硬件设备(微客赚-智能盒子)</div>
           </div>
        </div>
        <div class="sollor">
        <div class="groub">          
          <div class="sitem bg1">
            <div class="sit">加入计划</div>
            <div class="ts1">灵活选择参与</div>
            <div class="ts1">不同招募形式</div>
          </div>
             <img
            src="../assets/ximg/sanjiao@2x.png"
            alt="NFT"
            title="NFT"
            class="img"
          />
           <div class="sitem bg2">
            <div class="sit">绑定设备</div>
            <div class="ts1">提供多种绑定方式 </div>
            <div class="ts1">支持各类设备接入</div>
          </div>
             <img
            src="../assets/ximg/sanjiao@2x.png"
            alt="NFT"
            title="NFT"
            class="img"
          />
           <div class="sitem bg3">
            <div class="sit">查看收益</div>
            <div class="ts1">设备实时管理 </div>
            <div class="ts1">收益实时查看</div>
          </div>
        </div>
        </div>
      </div>
    </div>
   

     <div class="swarp" v-if="!show">
        <div class="contbox">
      <img src="../assets/ximg/iphone@2x.png" class="phone" />
      <div class="box">
        <div class="stopd">
          <img src="../assets/ximg/logo@2x.png" class="logo" />
          <div class="aitxt">
            <img src="../assets/ximg/xingke@2x.png" class="xingke" />
            <div>共享宽带奖励计划</div>
          </div>
        </div>
        <div class="title">让宽带自己去赚钱</div>
        <div class="tips">下载微客赚，获取稳定收益</div>
        <div class="download" @click="todown">
          <img src="../assets/ximg/downs.png" class="qcode" v-if="isIos" />
          <img src="../assets/ximg/down.png" class="qcode"  v-else/>
          
        </div>
      </div>
      </div>
    </div>
    <div class="swarps" v-if="show">
        <div class="stop">
            <div class="title">宽带共享奖励计划</div>
            <div class="sit">下载微客赚APP 获取稳定收益</div>
        </div>
        <img src="../assets/ximg/background@2x.png" class="Android" />
        <div class="btn" @click="todown">APP下载</div>
    </div>
    <mbottom />
  </div>
</template>

<script>
import mtop from "../components/top.vue";
import mbottom from "../components/bottom.vue";

export default {
  data() {
    return {
      show: false,
      isIos:false
    };
  },
  components: {
    mtop,
    mbottom,
  },

  mounted() {
    if (this._isMobile()) {
      this.show = true;
    } else {
      this.show = this.$store.getters.phone;
    }
    //   let divgao=document.querySelector(".right_box1").offsetTop;
    //   this.heightList.push(divgao);
    //   window.addEventListener('scroll',this.handlerScroll,true)
  },
  watch: {
    "$store.getters.phone": function (val) {
      this.show = val;
    },
    deep: true,
    immediate: true,
  },
  created() {
    var u =navigator.userAgent
      this.isIos= !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  },
  methods: {
    todown() {
      // this.$toast("敬请期待");
        var u =navigator.userAgent
        let isIos= !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
        // console.log(isIos);
        let url='https://wky.xingrunkeji.cn/App/wkz.apk'
        let surl='https://apps.apple.com/cn/app/%E5%BE%AE%E5%AE%A2%E8%B5%9A/id6471359638'
        window.open(isIos?surl:url)
      // window.open(
      //   "https://wky.xingrunkeji.cn/App/app.apk"
      // );
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    topage(e) {
      this.$router.replace({ path: e });
      this.$store.commit("sid", "");
    },
    // tobuy() {
    //    location.href = "https://opensea.io/collection/cupid-love-metaverse";
    // },
  },
};
</script>
<style lang="scss" scoped>
$t-mf: "Arial";
.main {
  background: #ffffff;
  // background: pink;
  min-height: 100vh;
  max-width: 100%;
  min-width: 1200px;
  width: 100%;
  .banner{
    width: 100%;
    padding-top:70px;
    font-size: 0;
    .wky{
        width: 100%;
        height: auto;
    }
  }
  .swarp {
    background: url("../assets/ximg/banner@2x.png") no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 1010px;
    display: flex;
    align-items: center;
    justify-content: center;
    .contbox{
        width: 1200px;
         display: flex;
    align-items: center;
    justify-content: flex-start;
    }
    .phone {
      width: 617px;
      height: 617px;
      margin-right: 103px;
    }
    .box {
      .stopd {
        display: flex;
        align-items: center;
        .logo {
          width: 167px;
          height: 167px;
          margin-left: -22px;
        }
        .aitxt {
          margin-left: 0px;
          .xingke {
            width: 188px;
            height: 70px;
          }
          font-size: 24px;
          font-weight: 400;
          color: #86909C;
          line-height: 36px;
        }
      }
      .title {
        font-size: 36px;
        font-weight: 600;
        color: #1d2129;
        line-height: 50px;
        margin-top: 80px;
      }
      .tips {
        font-size: 24px;
        font-weight: 400;
        color: #1d2129;
        line-height: 36px;
        margin-top: 8px;
      }
      .download {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 231px;
        height: 231px;
        background: #FFFFFF;
        box-shadow: 10px 10px 24px 0px rgba(192,226,208,0.73);
        border-radius: 17px;
        margin-top: 47px;
        .qcode{
          width: 187px;
          height: 187px;
        }
      }
    }
  }
  .imgNum{
    width: 100%;
    height: 270px;
    .img{
        width: 100%;
        height: 100%;
    }
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    .contbox {
      width: 1200px;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      padding-top: 80px;
    }

    .txtbox {
        width: 100%;
          display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      .title {
        font-size: 24px;
        font-weight: 600;
        color: #1d2129;
        line-height: 33px;
        position: relative;
        padding-left: 12px;
      }
  
      .next {
        width:1200px;
        margin-top: 12px;
        .t1 {
          font-size: 16px;
          font-weight: 400;
          color: #86909C;
          margin-bottom: 16px;
           text-align: center;
        }
       
      }
    }
    .imgbox {
      width: 1100px;
      height:auto;
          margin: 60px auto;
      .img {
        width: 100%;
        height: 100%;
      }
    }

    .groub{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 80px auto;

        .img{
            width: 74px;
            height: 13px;
            margin: 0 59px;
        }
        .sitem{
            width: 272px;
            height: 294px;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            padding: 30px;
            box-sizing: border-box;
            .sit{
                font-size: 28px;
                font-weight: 600;
                line-height: 40px;
                margin-bottom:12px ;
            }
            .ts1{
                font-size: 16px;
                font-weight: 400;
                color: #212422;
                line-height: 22px;
            }

        }
        .bg1{
            background: url("../assets/ximg/01@2x.png") no-repeat;
            background-size: 100% 100%;
            .sit{                
                color: #1FD88A;
            }
        }
         .bg2{
            background: url("../assets/ximg/02@2x.png") no-repeat;
            background-size: 100% 100%;
            .sit{                
                color: #457DF6;
            }
        }
         .bg3{
            background: url("../assets/ximg/03@2x.png") no-repeat;
            background-size: 100% 100%;
            .sit{                
                color: #F09908;
            }
        }
}
  }
  .wbg {
    background: #ffffff;
  }
}
.h5main {
  background: #f7f7f7;
  min-height: 100vh;
  max-width: 750px;
  min-width: 640px;
  width: 100%;
  .swarps {
    flex-direction: column;
    background-size: 100% 100%;
    height: auto;
    position: relative;
    .stop{
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        width: 100%;
        position: absolute;
        top: 164px;
        .title{
          font-size: 48px;
font-weight: 600;
color: #1D2129;
line-height:66px;
text-align: center;
        }
        .sit{
            font-size: 32px;
font-weight: 500;
color: #86909C;
line-height: 44px;
text-align: center;
        }
    }
   .Android{
    width: 100%;
    height: 100%;
   }
    .btn {
      position: absolute;
      bottom: 110px;
     width: 532px;
height: 92px;
background: #FFFFFF;
box-shadow: 6px 6px 12px 0px rgba(2,143,83,0.3);
border-radius: 46px;
font-size: 32px;
font-weight: 500;
display: flex;
align-items: center;
justify-content: center;
color: #02A962;
line-height: 22px;
left: 50%;
transform: translateX(-50%);
cursor: pointer;
    }
  }
    .banner{
    width: 100%;
    padding-top:145px;
    font-size: 0;
    display: flex;
        align-items: center;
        justify-content: center;
    .wky{
        width: 1000px;
        height: auto;
    }
  }
   .imgNum{
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        .img{
            width: 100%;
            
        }
      }
  .content {
    width: 100%;
    .contbox {
      width: calc(100% - 52px);
      height: auto;
      padding: 26px;
      flex-direction: column;
      .imgbox {
        width: 100%;
        height: auto;
        margin: 100px 40px;
      }
      .txtbox {
        width: 100%;
        .title {
          font-size: 32px;
          margin: 52px auto 16px;
        }
        .next {
          width: 100%;
          .t1 {
            font-size: 28px;
            color: #86909C;
          }
          .t2 {
            font-size: 26px;
          }
        }
      }
      .sollor{
        overflow: hidden;
        // display: flex;
        // align-items: center;
        // justify-content: flex-start;
        width: 100%;
      .groub{
        overflow: auto;
       width: 100%;
        .img{
            width: 148px;
            height: 26px;
            margin:  100px 0;
            transform: rotate(90deg);
            display: none;
        }
        .sitem{
            width: 544px;
            height: 568px;
            min-width: 544px;
            padding: 60px;
            margin-right: 48px;
            .sit{
                font-size: 56px;
                 line-height: 80px;
                margin-bottom:24px ;
            }
             .ts1{
                font-size: 32px;
                line-height: 44px;
            }
        }
      }
      
    }
     
    }
    
  }
  .wbg {
    .contbox {
      flex-direction: column-reverse;
    }
  }
}
</style>